import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import TopCategories from '@theme/components/category/TopCategories'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  components: {
    AppTitle,
    Breadcrumbs,
    ProductsVisited,
    TopCategories,
  },
  props: {
    site: {
      type: String,
      default: 'eshop',
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('PageForbidden.breadcrumbs.index'),
          path: this.localePath({ name: 'index' }),
        },
      ],
    }
  },
  head() {
    return this.getSeoHead({ yamlKey: 'PageForbidden' })
  },
  mixins: [SeoMixin],
  mounted() {
    this.$eventBus.$emit(eventDefinitions.NOT_FOUND.PAGE_VIEW)
  },
}
