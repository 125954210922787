<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'ProductsVisitedLazy',
      componentFactory: () => import('./ProductsVisited.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.ProductsVisitedLazy > .PlaceholderBox {
  min-height: 611px;
}
</style>
