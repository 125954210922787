import AppLink from '@theme/components/atom/AppLink'
import BreadcrumbFirstItem from '@theme/components/molecule/BreadcrumbFirstItem'

export default {
  components: {
    AppLink,
    BreadcrumbFirstItem,
  },
  props: {
    enableBreadcrumbs: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.Breadcrumbs.enable
      },
    },
    enableHomeItem: {
      type: Boolean,
      default: true,
    },
    links: {
      type: Array,
      required: true,
    },
    hideLastItemOnMobile: {
      type: Boolean,
      default: false,
    },
  },
}
